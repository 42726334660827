(function(i, p, w, d, b, t) {

    var native_article_ad = {
        adConfig: {},

        config: function(settings) {
            obj = {};
            
            var hTag = {
                'small': 'h4',
                'medium': 'h3',
                'large': 'h2'
            };
            
            var imagePos = {
                'top': ' image-top',
                'middle': ' image-middle',
                'bottom': ' image-bottom'
            };
            
            obj.hTag = hTag[settings.headline_size];
            obj.id = settings.line_item_id + '-' + settings.creative_id;
            obj.imgAlignClass = (settings.image_align === 'right') ? ' image-right' : '';
            obj.imgPosClass = imagePos[settings.image_pos];

            return obj;
        },
        
        imageHtml: function(settings) {
            var html = '';
            var posClass = '';
            if (settings.display_mode === 'vertical') {
                posClass = ' card-image-' + settings.image_pos;
            }
            html += '<div class="card-image'+ posClass +'">';
            html += '<div class="media-preview article-asset" data-aspect="16:9">' +
                        '<figure class="photo layout-horizontal">' +
                            '<div class="image">' +
                                '<a href="'+ settings.click_tag +'" target="_blank" rel="noopener">' +
                                    '<div class="asset-icon sponsored">Sponsored</div>' +
                                    '<img itemprop="image" class="img-responsive full" src="' + settings.image + '" data-sizes="auto">' +
                                '</a>' +
                            '</div>' +
                        '</figure>' +
                    '</div>';
            html += '</div>';
            
            return html;
        },

        html: function(settings) {
            html = '';
            if (settings.display_mode === 'horizontal') {
                html += '<article id="native-ad-' + this.adConfig.id + '" class="clearfix card summary text-left has-image'+ this.adConfig.imgAlignClass +'">';
            } else {
                html += '<div class="card-grid">';
                html += '<article id="native-ad-' + this.adConfig.id + '" class="clearfix card summary text-left has-image'+ this.adConfig.imgPosClass +'">';
            }
            html += '' +
                '<div class="card-container">';
                    if (settings.display_mode === 'horizontal' || (settings.display_mode === 'vertical' && settings.image_pos === 'top')) {
                        html += this.imageHtml(settings);
                    }
                    html += '' +
                    '<div class="card-body">';
                        if (settings.attribution) {
                            html += '' +
                            '<div class="card-labels">' +
                                '<div class="card-label-section text-muted">' +
                                    '<span><a href="'+ settings.click_tag +'" target="_blank" rel="noopener">'+ settings.attribution +'</a></span>' +
                                '</div>' +
                            '</div>';
                        }
                        html += '' +
                        '<div class="card-headline">' +
                            '<'+ this.adConfig.hTag +' class="tnt-headline">' +
                                '<a href="'+ settings.click_tag +'" target="_blank" rel="noopener">'+ settings.headline +'</a>' +
                            '</'+ this.adConfig.hTag +'>' +
                        '</div>';
                        if (settings.display_mode === 'vertical' && settings.image_pos === 'middle') {
                            html += this.imageHtml(settings);
                        }
                        if (settings.summary) {
                            html += '' +
                            '<div class="card-lead">' +
                                '<p class="tnt-summary">' + settings.summary + '</p>' +
                            '</div>';
                        }
                        if (settings.display_mode === 'vertical' && settings.image_pos === 'bottom') {
                            html += this.imageHtml(settings);
                        }
                    html += '' +
                    '</div>' +
                '</div>' +
                '<div class="clearfix"></div>' +
            '</article>';
            if (settings.display_mode === 'vertical') {
                html += '</div>';
            }
            
            if (settings.tracking_pixel) {
                html += '<div style="display:none;"><img src="' + this.settings.tracking_pixel + '" height="1" width="1"></div>';
            }
            
            return html;
        },

        load: function(obj) {
            this.adConfig = this.config(obj);
            p.parentNode.insertAdjacentHTML('afterend', this.html(obj));
        }
    };

    try {
        if (!i) throw 'Friendly iframe required.';

        t.cmds.forEach(function(item) {
            native_article_ad.load(item.call());
            return false;
        });
    } catch(e) {
        if (w.console) w.console.warn(e);
    }
})(
    (window.inDapIF ? true : false),
    (window.inDapIF ? window.frameElement : null),
    (window.inDapIF ? window.parent : window),
    (window.inDapIF ? window.parent.document : document),
    (window.inDapIF ? window.parent.document.body : document.body),
    (window.__TNT_AD || {})
);